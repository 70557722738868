<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-end mb-3">
        <button
          class="btn btn-sm btn-primary btn-circle"
          @click="getItems()"
          :disabled="bLoading"
        >
          <i class="fa fa-sync-alt" v-if="!bLoading"></i>
          <span v-else>Cargando...</span>
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <DxDataGrid
          v-if="items.length"
          :data-source="items"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
        >
          <DxPaging :page-size="15" />
          <DxExport :enabled="true" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true" />
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            v-for="(column, idx) in columns"
            :key="idx"
            :data-field="column.dataField"
            :caption="column.caption"
            :data-type="column.type"
            :alignment="column.alignment"
            :group-index="column.group"
          />
          <!-- <DxColumn
            data-field="actions"
            alignment="center"
            caption="Acción"
            type="buttons"
            cell-template="cellTemplate"
          /> -->
          <template #cellTemplate>
            <!-- <div class="btn-group btn-group-sm justify-content-center">
            <button
              @click="traslateOT(cellData.data)"
              class="btn btn-sm btn-outline-success btn-icon"
              title="Trasladar"
              v-if="cellData.data.id_estado == 2 && can('traslate-ot')"
            >
              <i class="la la-exchange-alt"></i>
            </button>
          </div> -->
          </template>
        </DxDataGrid>
        <div class="text-center" v-else>
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxExport,
  DxGroupPanel,
} from "devextreme-vue/data-grid";
import axios from "axios";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
  },
  mounted() {
    // this.getItems();
  },
  data() {
    return {
      columns: [],
      pageSizes: [5, 15, 40, 50, 100],
      items: [],
      bLoading: false,
      form: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    getItems() {
      this.bLoading = true;
      axios
        .post("reporte-certificacion", this.form)
        .then((res) => {
          const data = res.data;
          console.log(data.items);
          this.items = data.items;
          this.columns = data.columns;

          this.bLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.bLoading = false;
        });
    },
  },
};
</script>
